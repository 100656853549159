import React, { lazy, useEffect, useState, Suspense } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import moment from 'moment-timezone';
import propTypes from 'prop-types';
import currencyToSymbolMap from 'currency-symbol-map/map';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Button from '../../../../components/library/button';
import Headline from '../../../../components/library/headline';
import InputSelect from '../../../../components/library/inputSelect';
import InputDate from '../../../../components/library/inputDate';
import InputText from '../../../../components/library/inputText';
import sBatchAddTerm from './batchAddTerm.scss';
import { isValidURL } from '../../../../../shared/helpers/General';
import { getTags } from '../../../../../actions/tagsActions';
import OneClickModal from '../oneClickModal';

const EditTextArea = lazy(() => import('../../../../../styledComponents/styles/EditTextArea.styled'));

export const enhance = compose(withStyles(sBatchAddTerm));

function BatchAddTerm(props) {
  const dispatch = useDispatch();
  const timezone = useSelector(state => state.profile.orgTimezone);
  const [saving, setSaving] = useState(false);
  const [showBatchAddTerm, setShowBatchAddTerm] = useState(false);
  const [submitValues, setSubmitValues] = useState();
  const [weeks, setWeeks] = useState('');
  const addTerm = useSelector(state => state.reports.batchAddTerm);
  const termTags = useSelector(state => state.tags.tags);
  const program_range_term_name = useSelector(state => state.termNames.termNamesActive.data);
  const [programTermOptions, setProgramRangeTerms] = useState([]);
  const [tabSelected, setTabSelected] = useState('info');
  const [validURL, setValidURL] = useState(false);

  const currencyCodeOptions = Object.keys(currencyToSymbolMap).map(currencyCode => ({
    value: currencyCode,
    label: currencyCode,
  }));

  const termTagOptions = termTags.map(termTags => ({
    value: termTags.id,
    label: termTags.name,
  }));

  let auckland =
    window.location.href.includes('360international.ac.nz') || window.location.href.includes('aucklandabroad.ac.nz');

  const [defaultValues, setDefaultValues] = useState(() => {
    let values = {
      taggables_attributes: null,
      currency_code: auckland ? 'NZD' : 'USD',
      cost_notes: '',
      cost_embedded_url: '',
      end_date: '',
      high_cost_cents: '',
      low_cost_cents: '',
      max_course_credits: '',
      min_course_credits: '',
      name: '',
      start_date: '',
      use_exact_dates: false,
      weeks: '',
    };
    return values;
  });

  const resetForm = () => {
    setTabSelected('info');
    setDefaultValues(() => {
      let values = {
        taggables_attributes: null,
        currency_code: auckland ? 'NZD' : 'USD',
        cost_notes: '',
        cost_embedded_url: '',
        end_date: '',
        high_cost_cents: '',
        low_cost_cents: '',
        max_course_credits: '',
        min_course_credits: '',
        name: '',
        start_date: '',
        use_exact_dates: false,
      };
      setWeeks('');
      return values;
    });
  };

  let customStyles = {
    multiValue: provided => ({
      ...provided,
      textTransform: 'capitalize',
    }),
    option: (provided, state) => ({
      ...provided,
      textTransform: 'capitalize',
    }),
    container: (provided, state) => ({
      ...provided,
      textTransform: 'capitalize',
    }),
    placeholder: provided => ({
      ...provided,
      color: '#043544',
    }),
  };

  let termCustomStyles = {
    multiValue: provided => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
    }),
    container: (provided, state) => ({
      ...provided,
    }),
    placeholder: provided => ({
      ...provided,
      color: '#043544',
    }),
  };

  const handleWeekConversion = (end_date, start_date) => {
    const weeks = (moment(end_date).diff(moment(start_date), 'days') + 1) * (1 / 7);
    const adjustedWeeks = weeks ? (Math.round(weeks * 10) / 10).toFixed(1) : null;

    setWeeks(adjustedWeeks);
  };

  const bulletedMessage = (
    <ul>
      <li>Via will not prevent admins from adding multiple terms with the same term name and/or dates</li>
      <li>This addition will not edit existing terms</li>
      <li>To edit terms, head to {props.customAliases.alias_program.toLowerCase()} settings</li>
    </ul>
  );

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    borderBottom: '1px solid #ebebeb',
  };
  const groupBadgeStyles = {
    backgroundColor: '#ebebeb',
    borderRadius: '2em',
    color: '#043544',
    display: !props.showGroupCounts ? 'none' : 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.2em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  useEffect(() => {
    if (addTerm) {
      if (addTerm.loading) {
        setSaving(true);
      }

      if (saving && !!addTerm.data) {
        setSaving(false);
        if (!!addTerm.data.message) {
          setTabSelected('info');
          props.onClose(false);
        } else if (addTerm.data.data.length > 0) {
          setTabSelected('info');
          props.onClose(false);
        }
      }
    }
  }, [addTerm.loading]);

  useEffect(() => {
    if (program_range_term_name !== null) {
      let active = [];
      let archived = [];

      program_range_term_name.data.map(item => {
        if (item.attributes.name.length > 0) {
          if (!item.attributes.archived) {
            active.push({
              value: item.id,
              label: item.attributes.name,
            });
          } else {
            archived.push({
              value: item.id,
              label: item.attributes.name,
            });
          }
        }
      });
      const groupOptions = [
        { label: 'Active', options: active },
        { label: 'Archived', options: archived },
      ];

      setProgramRangeTerms(groupOptions);
    }
  }, [program_range_term_name]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      dispatch(getTags());
    }

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => {
          setTabSelected('info');
          setValidURL(false);
          resetForm();
          props.onClose(false);
        }}
        headerText="Add Term"
      >
        <div className={sBatchAddTerm['batch-add-term-modal']}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={values => {
              const errors = {};

              let isBeforeCurrentDate = (start_date, end_date) => {
                const startDate = moment(new Date(start_date)).format('MMMM D, YYYY h:mm a');
                const endDate = moment(new Date(end_date)).format('MMMM D, YYYY h:mm a');
                return moment(endDate).isSameOrAfter(startDate);
              };

              if (!values?.start_date) {
                errors.start_date = 'Start Date Required';
              }
              if (!values?.end_date) {
                errors.end_date = 'End Date Required';
              }
              if (!isBeforeCurrentDate(values?.start_date, values?.end_date)) {
                errors.end_date = 'End Date must be on or after Start Date';
              }

              if (parseInt(values?.max_course_credits) < parseInt(values?.min_course_credits)) {
                errors.max_course_credits = 'Maximum credits must be equal or more than the minimum credits';
              }

              if (
                parseFloat(values?.high_cost_cents) >= 0 &&
                parseFloat(values?.high_cost_cents) < parseFloat(values?.low_cost_cents)
              ) {
                errors.high_cost_cents = 'Maximum cost must be equal or more than the minimum cost';
              }

              if (values?.cost_embedded_url?.length > 0 && !isValidURL(values?.cost_embedded_url)) {
                errors.cost_embedded_url = 'Please enter a valid URL';
              }

              return errors;
            }}
            onSubmit={values => {
              let tagId = values?.taggables_attributes?.map(attribute => ({
                tag_id: attribute.value,
              }));

              const newStart = moment
                .tz(`${values.start_date.format('YYYY-MM-DD')} 12:00:00`, timezone)
                .tz(timezone)
                .format();

              const newEnd = moment
                .tz(`${values.end_date.format('YYYY-MM-DD')} 12:00:00`, timezone)
                .tz(timezone)
                .format();

              const submitValues = {
                program_range: {
                  taggables_attributes: !!tagId ? tagId : [],
                  currency_code: values.currency_code,
                  cost_notes: values.cost_notes,
                  cost_embedded_url: values?.cost_embedded_url?.length === 0 ? null : values.cost_embedded_url,
                  end_date: newEnd,
                  high_cost_cents: values.high_cost_cents === '' ? null : values.high_cost_cents * 100,
                  low_cost_cents: values.low_cost_cents === '' ? null : values.low_cost_cents * 100,
                  max_course_credits: values.max_course_credits,
                  min_course_credits: values.min_course_credits,
                  term_name_id: values.name.value,
                  start_date: newStart,
                  use_exact_dates: values.use_exact_dates,
                  weeks: weeks,
                },
                program_ids: props.programIds,
                source: 'internal',
              };

              setSubmitValues(submitValues);
              props.onClose(false);
              setShowBatchAddTerm(true);
            }}
          >
            {({ values, handleSubmit, errors, validateForm }) => (
              <>
                <Form>
                  <div className={sBatchAddTerm.formBody}>
                    <div className={sBatchAddTerm.tabContainer}>
                      <div
                        className={`${sBatchAddTerm.tabItem} ${tabSelected === 'info' ? sBatchAddTerm.selected : ''}`}
                        onClick={() => setTabSelected('info')}
                        tabIndex={0}
                        onKeyDown={e => {
                          if (e.keyCode === 32 || e.keyCode === 13) {
                            setTabSelected('info');
                          }
                        }}
                      >
                        Term Info
                      </div>
                      <div
                        className={`${sBatchAddTerm.tabItem} ${tabSelected === 'cost' ? sBatchAddTerm.selected : ''}`}
                        onClick={() => {
                          validateForm().then(data => {
                            delete data?.low_cost_cents;
                            delete data?.high_cost_cents;
                            delete data?.cost_embedded_url;

                            if (Object.keys(data).length === 0) {
                              setTabSelected('cost');
                            }
                          });
                        }}
                        tabIndex={0}
                        onKeyDown={e => {
                          if (e.keyCode === 32 || e.keyCode === 13) {
                            validateForm().then(data => {
                              delete data?.low_cost_cents;
                              delete data?.high_cost_cents;
                              delete data?.cost_embedded_url;

                              if (Object.keys(data).length === 0) {
                                setTabSelected('cost');
                              }
                            });
                          }
                        }}
                      >
                        Cost
                      </div>
                    </div>
                    {tabSelected === 'info' ? (
                      <>
                        <div className={sBatchAddTerm.section}>
                          <div className={sBatchAddTerm.row}>
                            <div className={sBatchAddTerm.item}>
                              <Field name="start_date">
                                {({ form, field }) => (
                                  <InputDate
                                    id="start_date"
                                    name="start_date"
                                    aria-label="Start Date"
                                    labelText="Start Date"
                                    helpText=""
                                    errorMessage={errors.start_date}
                                    isRequired
                                    value={field.start_date}
                                    onChange={e => {
                                      handleWeekConversion(values.end_date, e);
                                      form.setFieldValue('start_date', e);
                                    }}
                                    placeholder={
                                      values.start_date
                                        ? moment(values.start_date)
                                            .tz(timezone)
                                            .format('MMM D, YYYY')
                                        : 'Select Start Date'
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                            <div className={sBatchAddTerm.item}>
                              <Field name="end_date">
                                {({ form, field }) => (
                                  <InputDate
                                    id="end_date"
                                    name="end_date"
                                    aria-label="End Date"
                                    labelText="End Date"
                                    helpText=""
                                    errorMessage={errors.end_date}
                                    isRequired
                                    value={field.end_date}
                                    onChange={e => {
                                      handleWeekConversion(e, values.start_date);
                                      form.setFieldValue('end_date', e);
                                    }}
                                    minDate={values.start_date}
                                    placeholder={
                                      values.end_date
                                        ? moment(values.end_date)
                                            .tz(timezone)
                                            .format('MMM D, YYYY')
                                        : 'Select End Date'
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                            <div className={sBatchAddTerm.item}>
                              <Field name="use_exact_dates">
                                {({ form, field }) => (
                                  <InputSelect
                                    id="use_exact_dates"
                                    name="use_exact_dates"
                                    aria-label="Show Exact Dates Toggle"
                                    labelText="Show Exact Dates"
                                    helpText=""
                                    errorMessage={errors.use_exact_dates}
                                    isRequired
                                    defaultValue={
                                      !!values.use_exact_dates
                                        ? { value: values.use_exact_dates.value, label: values.use_exact_dates.label }
                                        : { value: false, label: 'No' }
                                    }
                                    options={[
                                      { value: true, label: 'Yes' },
                                      { value: false, label: 'No' },
                                    ]}
                                    placeholder={field.use_exact_dates ? field.use_exact_dates.label : 'No'}
                                    value={values.use_exact_dates.value}
                                    onChange={e => form.setFieldValue('use_exact_dates', e.value)}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className={sBatchAddTerm.row}>
                            <div className={sBatchAddTerm.item}>
                              <Field name="name">
                                {({ form }) => (
                                  <InputSelect
                                    id="name"
                                    name="name"
                                    aria-label="Term Name"
                                    labelText="Term Name"
                                    helpText=""
                                    styles={termCustomStyles}
                                    formatGroupLabel={formatGroupLabel}
                                    value={values.name.value}
                                    options={programTermOptions}
                                    placeholder={values.name ? values.name.label : 'Select Term Name'}
                                    onChange={e => form.setFieldValue('name', e)}
                                  />
                                )}
                              </Field>
                            </div>
                            <div className={sBatchAddTerm.item}>
                              <Field name="taggables_attributes">
                                {({ form }) => (
                                  <InputSelect
                                    id="taggables_attributes"
                                    name="taggables_attributes"
                                    aria-label="Term Tags"
                                    labelText="Term Tags"
                                    helpText=""
                                    isMulti={true}
                                    options={termTagOptions}
                                    value={values.taggables_attributes}
                                    placeholder={
                                      values.taggables_attributes
                                        ? values.taggables_attributes.label
                                        : 'Select Term Tags'
                                    }
                                    onChange={e => form.setFieldValue('taggables_attributes', e)}
                                  />
                                )}
                              </Field>
                            </div>

                            <div className={sBatchAddTerm.duration}>
                              Duration:{' '}
                              <span>
                                {weeks === 1.0
                                  ? `${parseFloat(weeks)} week`
                                  : `${weeks ? parseFloat(weeks) : ''} weeks`}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className={sBatchAddTerm.section}>
                          <Headline tag="h2" as="h3">
                            Credits
                          </Headline>
                          <div className={sBatchAddTerm.row}>
                            <div className={sBatchAddTerm.item}>
                              <Field name="min_course_credits">
                                {({ form }) => (
                                  <InputText
                                    id="min_course_credits"
                                    name="min_course_credits"
                                    aria-label="Credit Minimum"
                                    labelText="Credit Minimum"
                                    helpText=""
                                    value={values.min_course_credits}
                                    errorMessage={errors.min_course_credits}
                                    size="addTermModal"
                                    type="number"
                                    min={0}
                                    placeholder="Enter Credit Minimum"
                                    onChange={e => {
                                      if (e.target.value === '' || e.target.value >= 0)
                                        form.setFieldValue('min_course_credits', parseInt(e.target.value));
                                    }}
                                  />
                                )}
                              </Field>
                            </div>
                            <div className={sBatchAddTerm.item}>
                              <Field name="max_course_credits">
                                {({ form }) => (
                                  <InputText
                                    id="max_course_credits"
                                    name="max_course_credits"
                                    aria-label="Credit Maximum"
                                    labelText="Credit Maximum"
                                    helpText=""
                                    value={values.max_course_credits}
                                    errorMessage={errors.max_course_credits}
                                    size="addTermModal"
                                    type="number"
                                    min={0}
                                    placeholder="Enter Credit Maximum"
                                    onChange={e => {
                                      if (e.target.value === '' || e.target.value >= 0)
                                        form.setFieldValue('max_course_credits', parseInt(e.target.value));
                                    }}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={sBatchAddTerm.finalSection}>
                          <div className={sBatchAddTerm.row}>
                            <div className={sBatchAddTerm.item}>
                              <Field name="low_cost_cents">
                                {({ form }) => (
                                  <InputText
                                    id="low_cost_cents"
                                    name="low_cost_cents"
                                    aria-label="Cost Minimum"
                                    labelText="Cost Minimum"
                                    helpText=""
                                    value={values.low_cost_cents}
                                    size="addTermModal"
                                    errorMessage={errors.low_cost_cents}
                                    type="number"
                                    step="0.01"
                                    min={0}
                                    placeholder="Enter Cost Minimum"
                                    onChange={e => {
                                      if (e.target.value === '' || e.target.value >= 0)
                                        form.setFieldValue('low_cost_cents', e.target.value);
                                    }}
                                  />
                                )}
                              </Field>
                            </div>
                            <div className={sBatchAddTerm.item}>
                              <Field name="high_cost_cents">
                                {({ form }) => (
                                  <InputText
                                    id="high_cost_cents"
                                    name="high_cost_cents"
                                    aria-label="Cost Maximum"
                                    labelText="Cost Maximum"
                                    helpText=""
                                    value={values.high_cost_cents}
                                    size="addTermModal"
                                    errorMessage={errors.high_cost_cents}
                                    type="number"
                                    step="0.01"
                                    min={0}
                                    placeholder="Enter Cost Maximum"
                                    onChange={e => {
                                      if (e.target.value === '' || e.target.value >= 0)
                                        form.setFieldValue('high_cost_cents', e.target.value);
                                    }}
                                  />
                                )}
                              </Field>
                            </div>
                            <div className={sBatchAddTerm.item}>
                              <Field name="currency_code">
                                {({ form, field }) => (
                                  <InputSelect
                                    id="currency_code"
                                    name="currency_code"
                                    aria-label="Currency"
                                    labelText="Currency"
                                    helpText=""
                                    styles={customStyles}
                                    value={values.currency_code}
                                    options={currencyCodeOptions}
                                    placeholder={values.currency_code ? values.currency_code : 'Select Currency'}
                                    onChange={e => form.setFieldValue('currency_code', e.value)}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className={`${sBatchAddTerm.row} ${sBatchAddTerm.embedRow}`}>
                            <div className={sBatchAddTerm.item}>
                              <Field name="cost_embedded_url">
                                {({ form }) => (
                                  <div className={sBatchAddTerm.embedInputField}>
                                    <InputText
                                      id="cost_embedded_url"
                                      name="cost_embedded_url"
                                      aria-label="Cost embed URL"
                                      labelText="Cost embed URL"
                                      subLabelText="To embed your cost URL, copy and paste the Public URL of the content you want to display."
                                      value={values.cost_embedded_url}
                                      errorMessage={errors.cost_embedded_url}
                                      size="addTermModal"
                                      onChange={e => {
                                        const validCheck = isValidURL(e.target.value);
                                        setValidURL(validCheck);
                                        if (validCheck) errors.cost_embedded_url = '';
                                        form.setFieldValue('cost_embedded_url', e.target.value);
                                      }}
                                      greenCheck={validURL}
                                      checkInput={true}
                                      placeholder={'Enter Public URL'}
                                    />
                                    <div className={sBatchAddTerm.embedHelpLink}>
                                      Need help embedding content?
                                      <a
                                        href="https://help.viatrm.com/how-do-i-embed"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Read our knowledge base
                                      </a>
                                    </div>
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className={sBatchAddTerm.row}>
                            <div className={sBatchAddTerm.item}>
                              <Field name="cost_notes">
                                {({ form }) => (
                                  <Suspense fallback={<span />}>
                                    <EditTextArea
                                      aria-label={'Additional Term Cost Information'}
                                      id="cost_notes"
                                      name="cost_notes"
                                      label={'Additional Term Cost Information'}
                                      placeholder={`Enter Additional Term Cost Information`}
                                      openSans
                                      onChange={value => {
                                        form.setFieldValue('cost_notes', value);
                                      }}
                                    />
                                  </Suspense>
                                )}
                              </Field>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Form>
                <>
                  <div className={sBatchAddTerm.footer}>
                    {tabSelected === 'info' ? null : (
                      <div className={sBatchAddTerm['button-content']}>
                        <Button
                          display="secondary"
                          kind="solid"
                          size="medium"
                          aria-label="Back Button"
                          onClick={() => {
                            setTabSelected('info');
                          }}
                        >
                          {'< Back'}
                        </Button>
                      </div>
                    )}
                    <div className={sBatchAddTerm['right-buttons']}>
                      <div className={sBatchAddTerm['button-content']}>
                        <Button
                          display="secondary"
                          kind="solid"
                          size="medium"
                          aria-label="Cancel Button"
                          onClick={() => {
                            setTabSelected('info');
                            setValidURL(false);
                            props.onClose(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      {tabSelected === 'info' ? (
                        <div className={sBatchAddTerm['button-content']}>
                          <Button
                            display="primary"
                            kind="solid"
                            size="medium"
                            type="button"
                            aria-label="Next"
                            onClick={() => {
                              validateForm().then(data => {
                                delete data?.low_cost_cents;
                                delete data?.high_cost_cents;
                                delete data?.cost_embedded_url;

                                if (Object.keys(data).length === 0) {
                                  setTabSelected('cost');
                                }
                              });
                            }}
                          >
                            {'Next >'}
                          </Button>
                        </div>
                      ) : (
                        <div className={sBatchAddTerm['button-content']}>
                          <Button
                            display="primary"
                            kind="solid"
                            size="medium"
                            type="button"
                            aria-label="Add Term Button"
                            onClick={handleSubmit}
                          >
                            Add Term
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              </>
            )}
          </Formik>
        </div>
      </ViaModal>
      <OneClickModal
        show={showBatchAddTerm}
        onClose={() => {
          setTabSelected('info');
          setValidURL(false);
          resetForm();
          setShowBatchAddTerm(false);
          props.setSelectedPrograms();
        }}
        paragraphOne={`You are about to add a term to ${props.selectedPrograms.length} ${
          props.selectedPrograms.length > 1
            ? `${props.customAliases.alias_programs.toLowerCase()}`
            : `${props.customAliases.alias_program.toLowerCase()}`
        }.`}
        bulletedMessage={bulletedMessage}
        paragraphTwo={'Are you sure you want to continue?'}
        cancelMessage={'Cancel'}
        confirmActionMessage={'Add Term'}
        headline={'Add Term'}
        source="add_term"
        selectedPrograms={props.programIds}
        actionState={addTerm}
        paragraphTwoDemiBold
        addTermSuccessMessage={`Term added to ${props.selectedPrograms.length} ${
          props.selectedPrograms.length > 1
            ? props.customAliases.alias_programs.toLowerCase()
            : props.customAliases.alias_program.toLowerCase()
        }`}
        submitValues={submitValues}
        showAddTermIcon={true}
        centered
      />
    </>
  );
}

BatchAddTerm.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default enhance(BatchAddTerm);
